import {
  CLOSE_LOGIN,
  OPEN_LOGIN,
  TOGGLE_NAVIGATION,
  CLOSE_NAVIGATION
} from '../types';

const root = document.querySelector('#root')

export const openLogin = () => {
  root.classList.add('overflowRoot')
  document.body.classList.add('overflowRoot')
  return {type: OPEN_LOGIN}
};
export const closeLogin = () => {
  root.classList.remove('overflowRoot')
  document.body.classList.remove('overflowRoot')
  return {type: CLOSE_LOGIN}
};
export const toggleNavigation = () => ({type: TOGGLE_NAVIGATION});
export const closeNavigation = () => ({type: CLOSE_NAVIGATION})
