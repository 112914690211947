import React from 'react';
import NewPassword from '../components/NewPassword/NewPassword';
//styles
import login from '../components/Login/Login.module.scss';
import '../components/Login/Login.scss';
import Helmet from 'react-helmet';

const ResetPassword = (props) => {
  const token = props.match.params.token;

  return (
    <div>
      <Helmet>
        <title>Скидування паролю</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
      <div className={login.popup}>
        <div className={login.overlay} />
        <div className={login.body}>
          <NewPassword token={token}/>
          <div className={`${login.cloud} login-cloud`} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
