import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import * as Scroll from 'react-scroll';

import './CharacterDesc.scss'

const scroll = Scroll.animateScroll;


const CharacterDesc = (props) => {
  const [textShow, setTextShow] = useState(false);
  const descText = useRef();
  const {personText, desc} = props;
  const quote = personText.split('.');
  const author = quote.pop();
  const descWithParagraph = desc
    .split(/\n/)
    .filter(item => item !== '\r' && item !== '')

  const key = () => '_' + Math.random().toString(36).substr(2, 9);

  const scrollDesc = (evt) => {
    const element = evt.target;
    if (Math.floor(element.scrollHeight - element.scrollTop) === element.clientHeight) {
      setTextShow(true)
    } else {
      setTextShow(false)
    }
  }

  const scrollDesktopText = () => {
    if (descText.current) {
      descText.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="character-desc">

      <div ref={descText} onScroll={scrollDesc} className={classNames('character-desc__inner')}>
        <blockquote className="character-desc__blockquote">
          <p>{quote.join('.')}</p>
          <p className="cite-author">{author}</p>
        </blockquote>
        <div className="character-desc__text">
          {descWithParagraph.map(paragraph => <p key={key()}>{paragraph}</p>)}
        </div>
      </div>

      <div className="arrow-down" onClick={() => scroll.scrollToTop()}>
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.6667 0.5L13 12.4583L1.33333 0.5" stroke="#4E4E4E" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      <div className={classNames('arrow-down arrow-down--desktop', {
        'arrow-down--open': textShow
      })} onClick={scrollDesktopText}>
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.6667 0.5L13 12.4583L1.33333 0.5" stroke="#4E4E4E" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  );
};

export default CharacterDesc;
