import React, {useEffect} from 'react';
import {ReactComponent as AppleIcon} from './../../images/icons/apple-icon.svg';

import appleAuth from './AppleAuth.module.scss';

const AppleAuth = () => {

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: 'com.silpo.sunagancy.site',
      scope: 'name email',
      redirectURI: 'https://whoami.silpo.ua/api/authapple',
      responseType: 'code id_token',
      responseMode: 'form_post',
      usePopup: false //or false defaults to false
    });
  }, [])


  return (
      <button onClick={() => window.AppleID.auth.signIn()} className={appleAuth.button}>
        <AppleIcon width="29" height="30" className={appleAuth.icon} />
        <span>Увійти через Apple</span>
      </button>
  );
};

export default AppleAuth;
