import React from 'react';

import './CharTitle.scss';

const MyComponent = ({name, isyou}) => {
  return (
    <h1 className="char-title">
      {isyou && <span className="char-title__text">Та це ж ви:</span>}
      <span className="char-title__name">{name}</span>
    </h1>
  );
};

export default MyComponent;
