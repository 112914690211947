import React from 'react';
import sun from './img/sun.png';
import cloud from './img/cloud.png';
import './Sun.scss';

const Sun = () => {
  return (
    <div className="sun-char">
      <img src={sun} alt="" className="sun-char__sun"/>
      <img src={cloud} alt="" className="sun-char__cloud"/>
    </div>
  );
};

export default Sun;
