import React from 'react';

import img1 from './img/hero-text-left.png';
import img2 from './img/hero-text-right.png';
import imgCloud from './img/cloud.png';

import './Labels.scss';

const Labels = () => {
  return (
    <div className="labels-char">
      <img className="labels-char__text-1" width="199" height="106" src={img1} alt="" />
      <img className="hero-silpo-cloud" src={imgCloud} alt="" />
      <img className="labels-char__text-2" width="157" height="112" src={img2} alt="" />
    </div>
  );
};

export default Labels;
