// UI reducer types
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const OPEN_LOGIN = 'OPEN_LOGIN';
export const TOGGLE_NAVIGATION = 'OPEN_NAVIGATION';
export const CLOSE_NAVIGATION = 'CLOSE_NAVIGATION';
export const LOADING_UI = 'LOADING_UI';
export const LOADING_DONE = 'LOADING_DONE';
// user types
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const SAVE_RESULT = 'SAVE_RESULT';
export const RESET_ERRORS = 'RESET_ERRORS';
//questionReducer
export const ADD_QUESTION_RESULT = 'ADD_QUESTION_RESULT';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';
export const REMEMBER_STEP = 'REMEMBER_STEP';
// psychotype reducers
export const GET_USER_PSYCHOTYPE = 'GET_USER_PSYCHOTYPE';
export const GET_ALL_PSYCHOTYPE = 'GET_ALL_PSYCHOTYPE';
export const PSYCHOTYPE_LOADING = 'PSYCHOTYPE_LOADING';
export const PSYCHOTYPE_ERROR = 'PSYCHOTYPE_ERROR'
