import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:4000",
  baseURL: "",
});

class restApi {
  loginUser = async (userData) => {
    const { data } = await api.post("/api/login", userData);
    return data;
  };

  registrationUser = async (userData) => {
    const { data } = await api.post("/api/signup", userData);
    return data;
  };

  logout = async () => {
    const response = await api.get("/api/logout");
    return response.data;
  };

  getUserInfo = async () => {
    const response = await api.get("/api/userinfo");
    return response.data;
  };

  googleAuth = async ({ tokenId }) => {
    const { data } = await api.post("/api/authgoogle", {
      token: tokenId,
    });

    return data;
  };

  facebookAuth = async ({ userID, accessToken }) => {
    const { data } = await api.post("/api/authfacebook", {
      userID,
      accessToken,
    });
    return data;
  };

  getPsychotypeInfo = async (psychotypeId) => {
    const { data } = await api.get(`/api/gettype/${psychotypeId}`);
    return data;
  };

  getAllPsychotypes = async () => {
    const { data } = await api.get(`/api/gettypes`);
    return data;
  };

  sendResult = async (id) => {
    const { data } = await api.post("/api/saveres", {
      result: id,
    });
    return data;
  };

  getTestsCount = async () => {
    const { data } = await api.get("/api/getcounttest");
    return data;
  };

  resetPassword = async (email) => {
    const { data } = await api.post("/api/requestreset", email);
    return data;
  };

  restoreFormPassword = async ({ token, password }) => {
    const response = await api.post("/api/resetpassword", { token, password });
    return response.data;
  };

  editUserName = async (name) => {
    const { data } = await api.post("/api/changename", name);
    return data;
  };

  editUserPhone = async (phone) => {
    const { data } = await api.post("/api/setphone", phone);
    return data;
  };

  sendGuidInfo = async (info) => {
    const { data } = await api.post("/api/guid", { ...info });
    return data;
  };

  sendGuidLoginInfo = async (guid) => {
    const { data } = await api.post("/api/setguid", {
      guid: guid,
    });
    return data;
  };
}

export default new restApi();
