import produce from 'immer';
import {
  GET_ALL_PSYCHOTYPE,
  PSYCHOTYPE_LOADING,
  PSYCHOTYPE_ERROR
} from '../types';

const initialState = {
  userPsychotype: {},
  allPsychoTypes: [],
  loading: false
}

const psychotypeReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PSYCHOTYPE:
      state.allPsychoTypes = action.payload;
      state.loading = false;
      return state
    case PSYCHOTYPE_LOADING:
      state.loading = true;
      return state;
    case PSYCHOTYPE_ERROR:
      state.loading = false;
      // временно
      return state;
    default:
      return  state
  }
}, initialState)

export default psychotypeReducer
