import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {OPEN_LOGIN} from '../redux/types';

const ProtectRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch()
  const isSignedIn = useSelector(({user}) => user.authenticated);
  useEffect(() => {
    if (isSignedIn === false) {
      dispatch({type: OPEN_LOGIN})
    }

  }, [dispatch, isSignedIn]);


  if (isSignedIn === null) {
    return <div>Loading</div>
  } else {
    return (
      <Route
        {...rest}
        render={(props) => isSignedIn ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  }


}

export default ProtectRoute;
