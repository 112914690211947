import React, {useEffect} from 'react';
import MainHero from '../components/MainHero/MainHero';
import Helmet from 'react-helmet';
import {calculateFullDeviceHeight} from '../utils/fullHeight';

const Home = () => {

  useEffect(() => {
    return () => {
      if (navigator.userAgent.includes('Instagram')) {
        window.location.reload();
        calculateFullDeviceHeight();
      }
    };
  }, []);


  return (
    <React.Fragment>
      <Helmet>
        <title>Зрозумій себе</title>
        <meta name="description" content="— Сільпо розкриває все про ваше Я" />
      </Helmet>
      <MainHero />
    </React.Fragment>
  );
};

export default Home;
