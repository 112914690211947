import React, { useEffect } from "react";
import Questions from "../components/Questions/Questions";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";

const TestPage = (props) => {
  const isSignedIn = useSelector(({ user }) => user.authenticated);
  const guid = new URLSearchParams(props.location.search).get("guid");

  if (!isSignedIn && guid) {
    localStorage.setItem("guid", guid);
  }

  useEffect(() => {
    if (isSignedIn !== null && !isSignedIn) {
      window.location.href = "/oauth2/login/";
    }
  }, [isSignedIn]);

  return (
    <>
      <Helmet>
        <title>Тестування</title>
        <meta
          name="description"
          content="Зрозумій себе — Сільпо розкриває все про ваше Я."
        />
      </Helmet>
      {isSignedIn && <Questions />}
    </>
  );
};

export default TestPage;
