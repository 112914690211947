import {
  GET_USER_PSYCHOTYPE,
  GET_ALL_PSYCHOTYPE,
  PSYCHOTYPE_LOADING,
  PSYCHOTYPE_ERROR
} from '../types';

import Api from '../../api/restApi';

export const getPsychotypeAction = (id) => (dispatch) => {
  dispatch({type: PSYCHOTYPE_LOADING});
  Api.getPsychotypeInfo(id)
    .then(data => dispatch({type: GET_USER_PSYCHOTYPE, payload: data}))
    .catch(err => dispatch({type: PSYCHOTYPE_ERROR}))
}

export const getAllPsychotypes = () => (dispatch) => {
  dispatch({type: PSYCHOTYPE_LOADING});
  Api.getAllPsychotypes()
    .then(data => dispatch({type: GET_ALL_PSYCHOTYPE, payload: data}))
    .catch(err => dispatch({type: PSYCHOTYPE_ERROR}))
}


