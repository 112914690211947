import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import QuestionItem from '../QuestionItem/QuestionItem';
import QuestionImg from './QuestionImgs';

import history from '../../history';

import {useActions} from '../../hooks/useActions';

import CloseBtn from '../CloseBtn/CloseBtn';

import './Questions.scss';

import info from './questions.json';

const Questions = () => {
  const {addQuestion, rememberStepAction, resetQuestionAction} = useActions();
  const storeStep = useSelector(({questions}) => questions.step);
  // const [storeStep, setStep] = useState(storeStep);
  const result = useSelector(({questions}) => questions.result);

  const isUserChoiceAnswer = result[storeStep];

  const keys = Object.keys(info);
  const questions = [];
  keys.forEach(key => questions.push(...info[key]));

  const stepPercent = (storeStep + 1) / 18 * 100;

  const renderQuestions = () => {
    const {title, questionFirst, questionSecond} = questions[storeStep];
    return (
      <div className="questions__wrapper">
        <h2 className="questions__title">{title}</h2>
        <QuestionItem onChange={onAnswerHandler}
                      order={1}
                      value={questionFirst.value}
                      checked={result[storeStep] === questionFirst.value}
                      text={questionFirst.text}/>
        <QuestionItem onChange={onAnswerHandler}
                      order={2}
                      value={questionSecond.value}
                      checked={result[storeStep] === questionSecond.value}
                      text={questionSecond.text} />
      </div>
    )
  }

  const onAnswerHandler = (evt) => {
    addQuestion({step: storeStep, value: evt.target.value})
  }

  const nextButton = () => {
    return storeStep >= 17
      ? <Link
        className={classNames('questions__next', {'questions__next--disabled' :!isUserChoiceAnswer})}
        to="/result">Показати результат</Link>
      : <button className="questions__next"
                disabled={!isUserChoiceAnswer}
                onClick={() => rememberStepAction(storeStep + 1)}>Далі</button>
  }

  return (
    <div className="questions">
      <QuestionImg />
      <div className="questions__progress-wrapper">
        <div className="questions__progress" style={{width: `${stepPercent}%`}}/>
      </div>

      <div className="questions__body">
        <div className="questions__info">
          <div className="questions__fake-block" />
          <div className="questions__count">Крок {storeStep + 1} з 18</div>
          <div
            onClick={() => {
              resetQuestionAction()
              history.push('/')
            }}
            className="questions__complete"
            role="button"
            aria-label="Завершити тестування і обнулити результат">
            <CloseBtn ariaLabel="Завершити тестування і обнулити результат"/>
            <span>закінчити</span>
          </div>
        </div>
          {renderQuestions()}
        <div className="questions__nav-buttons">
          <button className="questions__prev" disabled={storeStep === 0} onClick={() => rememberStepAction(storeStep - 1)}>Назад</button>
          {nextButton()}
        </div>
      </div>
    </div>
  );
};

export default Questions;
