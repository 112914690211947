import React, { useState, useRef } from "react";
import { findDOMNode } from "react-dom";

import InputMask from "react-input-mask";

import "./EditInput.scss";

const EditPhone = ({ value = false }) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();

  return (
    <section className="edit-input">
      <InputMask
        value={inputValue}
        ref={(ref) => (inputRef.current = findDOMNode(ref))}
        onChange={(e) => setInputValue(e.target.value)}
        type="text"
        mask="+380 (99) 999-99-99"
        className="edit-input__control"
        disabled
      />
    </section>
  );
};

export default EditPhone;
