import React from 'react';
import char from './img/captain-arrow.png'
import './CaptainArrow.scss';

const CaptainArrow = () => {
  return (
    <div className="captain-arrow">
      <img src={char} alt=""  className="captain-arrow__img"/>
    </div>
  );
};

export default CaptainArrow;
