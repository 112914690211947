import React from 'react';
import EditInput from '../EditInput/EditInput';
import EditPhone from '../EditPhone/EditPhone';
import {useSelector} from 'react-redux';

import './UserEditInputs.scss';

const UserEditInputs = () => {
  const userInfo = useSelector(({user}) => user);
  const {name, phone} = userInfo;


  return (
    <form className="edit-form">
      <EditInput value={name} />
      <EditPhone value={phone} />
      {/*<EditPhone value={'222'} />*/}
    </form>
  );
};

export default UserEditInputs;
