import React, {useState} from 'react';
import LoginForm from '../LoginForm/LoginForm';
import {SET_LOGIN_ERROR} from '../../redux/types';
import {useDispatch} from 'react-redux';

import registration from '../Registration/Registration.module.scss';
import restApi from '../../api/restApi';

import './PasswordRecovery.scss';

const PasswordRecovery = ({setTab}) => {
  const [isSuccessChangePassword, setIsSuccessChangePassword] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    restApi.resetPassword(formValues)
      .then(data => {
        setIsSuccessChangePassword(true)
        setEmail(formValues.email)
      })
      .catch(err => dispatch({type: SET_LOGIN_ERROR, payload: err.response?.data}))
  }

  const renderForm = () => {
    if (isSuccessChangePassword) {
      return (<p className="reset-password__text">Ми надіслали посилання для скидування паролю на вашу пошту {email}</p>)
    }
    return (
      <LoginForm
        mode="reset"
        submitText="Відновити"
        onSubmit={onSubmit}>
      </LoginForm>
    )
  }

  return (
    <div className={registration.row}>
      <div className={registration.titleWrapper}>
        <h2 className={`decorative-title decorative-title--restorePass ${registration.title}`}>Відновлення паролю</h2>
      </div>
      {renderForm()}
      <div className={registration.bottomWrapper}>
      <span className={registration.forgetText}> Згадали пароль?
          <button
            onClick={() => setTab('signIn')}
            className={registration.forgetPasswordBtn}
            type="button">&nbsp;Заходьте
          </button>
        </span>
      </div>
    </div>
  );
};

export default PasswordRecovery;
