const required = value => (value && value.trim() ? undefined : '*Обов’язкове поле');
const emailValidate = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? '*Введіть коректну email адресу'
  : undefined;

const minLength = min => value =>
  value && value.trim().length < min ? `*Поле повинно містити не менше ${min} символів` : undefined;
const minLengthForName = minLength(3);
const minLengthForPassword = minLength(6);

export const registrationInputConfig = {
  name: {
    name: 'name',
    label: 'Iм’я',
    placeholder: 'Iм’я',
    autoComplete: 'name',
    validation: [required, minLengthForName]
  },
  email: {
    name: 'email',
    label: 'E-mail',
    placeholder: 'E-mail',
    autoComplete: 'email',
    type: 'email',
    validation: [required, emailValidate]
  },
  password: {
    name: 'password',
    label: 'Пароль',
    placeholder: 'Пароль',
    autoComplete: 'new-password',
    type: 'password',
    validation: [required, minLengthForPassword]
  },
}
