import {
  LOADING_UI,
  LOADING_DONE,
  SIGN_IN,
  SIGN_OUT,
  SET_LOGIN_ERROR,
  SAVE_RESULT,
  RESET_ERRORS,
} from "../types";
import { closeLogin } from "./uiActions";
import Api from "../../api/restApi";

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  Api.loginUser(userData)
    .then((data) => {
      dispatch({ type: LOADING_DONE });
      dispatch(signIn(data));
      // dispatch(closeLogin());
      // dispatch(reset('registrationForm'))
      // history.push('/cabinet')
      window.location.href = "/cabinet";
    })
    .catch((err) => {
      dispatch({ type: LOADING_DONE });
      dispatch({ type: SET_LOGIN_ERROR, payload: err.response?.data });
    });
};

export const signupUser = (newUserData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  Api.registrationUser(newUserData)
    .then((data) => {
      dispatch({ type: LOADING_DONE });
      dispatch(signIn(data));
      // dispatch(closeLogin());
      // dispatch(reset('registrationForm'));
      // history.push('/cabinet')
      window.location.href = "/cabinet";
    })
    .catch((err) => {
      dispatch({ type: LOADING_DONE });
      dispatch({ type: SET_LOGIN_ERROR, payload: err.response?.data });
      console.error(err);
    });
};

export const changeAuthenticated = (isSigned) => (dispatch) => {
  if (isSigned) {
    dispatch(signIn());
    dispatch(closeLogin());
  } else {
    dispatch(signOut());
  }
};

export const logoutUser = () => (dispatch) => {
  Api.logout()
    .then((data) => {
      data.redirect
        ? (window.location.href = data.redirect)
        : (window.location.href = "/");
    })
    .catch((err) => console.error(err));
};

export const getUserInfo = () => (dispatch) => {
  Api.getUserInfo()
    .then((data) => {
      if (data.authenticated) {
        dispatch(signIn(data));
      } else if (!data.authenticated) {
        dispatch(signOut());
      }
    })
    .catch((err) => console.error(err));
};

export const signupGoogle = (response) => (dispatch) => {
  Api.googleAuth(response)
    .then((data) => {
      dispatch(closeLogin());
      dispatch(signIn(data));
      // history.push('/cabinet')
      window.location.href = "/cabinet";
    })
    .catch((e) => signOut());
};

export const signupFacebook = (response) => (dispatch) => {
  Api.facebookAuth(response)
    .then((data) => {
      dispatch(closeLogin());
      dispatch(signIn(data));
      window.location.href = "/cabinet";
      // history.push('/cabinet')
    })
    .catch((e) => signOut());
};

export const signIn = (user) => {
  return {
    type: SIGN_IN,
    payload: user,
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const saveResult = (id) => (dispatch) => {
  dispatch({ type: SAVE_RESULT, payload: id });
};

export const resetFormErrors = () => (dispatch) => {
  dispatch({ type: RESET_ERRORS });
};
