import React, {useState} from 'react';
import LoginForm from '../LoginForm/LoginForm';

import registration from '../Registration/Registration.module.scss';
import restApi from '../../api/restApi';
import {Link} from 'react-router-dom';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const NewPassword = ({token}) => {
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [passwordDesc, setPasswordDesc] = useState('Введіть новий пароль');

  const onSubmit = (formValues) => {
    const resetPasswordData = {...formValues, token};
    restApi.restoreFormPassword(resetPasswordData)
      .then(data => {
        setIsPasswordChange(true)
        setPasswordDesc('Пароль успішно змінено')
      })
  }

  const renderForm = () => {
    if (isPasswordChange) {
      return (
        <Link to="/" className="methodology__test">
          <PrimaryButton text={'На головну'} />
        </Link>
      )
    }
    return (
      <LoginForm
        mode="newPassword"
        submitText="Відновити"
        onSubmit={onSubmit}>
      </LoginForm>
    )
  }

  return (
    <div className={registration.row}>
      <div className={registration.titleWrapper}>
        <h2 className={`decorative-title decorative-title--restorePass ${registration.title}`}>{passwordDesc}</h2>
      </div>
      {renderForm()}
    </div>
  );
};

export default NewPassword;
