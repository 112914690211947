import {useSelector} from 'react-redux';
import {useActions} from '../../hooks/useActions';
import {useCloseEscape} from '../../hooks/useCloseEscape';
import './Overlay.scss';

const Overlay = () => {
  const isNavigationShow = useSelector(({ui}) => ui.showNavigation);
  const showOverlay = isNavigationShow ? 'overlay--show' : '';
  const {closeNavigation} = useActions()
  useCloseEscape(closeNavigation);

  return (
    <div onClick={closeNavigation} className={`overlay ${showOverlay}`} />
  );
};

export default Overlay;
