import React, {useRef} from 'react';
import HeroInfo from '../HeroInfo/HeroInfo';

import CloudWoman from '../MainChars/CloudWoman/CloudWoman';
import LighterMan from '../MainChars/LighterMan/LighterMan';
import Plane from '../MainChars/Plane/Plane';
import CaptainArrow from '../MainChars/CaptainArrow/CaptainArrow';
import Sun from '../MainChars/Sun/Sun';
import Package from '../MainChars/Package/Package';
import RiskMan from '../MainChars/RiskMan/RiskMan';
import Clouds from '../MainChars/Clouds/Clouds';
import TopClouds from '../MainChars/TopClouds/TopClouds';

import CloudQuestion from '../MainChars/CloudQuestion/CloudQuestion';

import './MainHero.scss'


const MainHero = () => {
  const mainHeroCloud = useRef(null);

  return (
    <div className="main-hero">
      <div className="wrapper main-hero__wrapper">
        <div className="main-hero__row">

          <TopClouds />
          <CloudWoman />
          <LighterMan />
          <Plane />

          <Sun />
          <div className="main-hero__package-wrapper">
            <div className="main-hero__question2">
              <span className="main-hero__question2-text cloud-question__text-anim">Не потрібно міркувати</span>
              <CloudQuestion invert question={'question2'} />
            </div>
            <div className="main-hero__question3">
              <span className="main-hero__question3-text cloud-question__text-anim">Тест триває 5 хвилин</span>
              <CloudQuestion question={'question3'} />
            </div>
            <Package />
             <CaptainArrow />
          </div>

          <RiskMan />
          <Clouds />
          <HeroInfo />
        </div>
      </div>
      <div ref={mainHeroCloud} className="main-hero__cloud" />
    </div>

  );
};

export default MainHero;
