import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import restApi from '../api/restApi';
import Helmet from 'react-helmet';
import { useSaveResult } from '../hooks/useSaveResult';

import { Redirect } from 'react-router-dom';

import ShareButtons from '../components/ShareButtons/ShareButtons';

import CharImg from '../components/CharacterInfo/CharImg/CharImg';
import CharTitle from '../components/CharacterInfo/CharTitle/CharTitle';
import CharacterDesc from '../components/CharacterInfo/CharacterDesc/CharacterDesc';
import { useActions } from '../hooks/useActions';

import PrimaryButton from '../components/PrimaryButton/PrimaryButton';

const Result = () => {
  const [resultInfo, setResultInfo] = useState('');
  const { openLogin } = useActions();
  const isSignedIn = useSelector(({ user }) => user.authenticated);
  // отримуємо результат із хука тому що незареєстровані користувачі теж дивитимуться результат
  const resultId = useSaveResult();

  useEffect(() => {
    const guid = localStorage.getItem('guid');

    if (resultId) {
      restApi.getPsychotypeInfo(resultId).then((data) => {
        setResultInfo(data);
      });
    }

    if (resultId && isSignedIn) {
      restApi.sendResult(resultId).catch((err) => console.error(err));
    }

    if (resultId && !isSignedIn && guid) {
      const guidInfo = {
        psychotype: resultId,
        updatedAt: new Date().toISOString(),
        guid,
      };

      restApi.sendGuidInfo(guidInfo).catch((err) => console.error(err));
    }
  }, [resultId, isSignedIn]);

  if (resultId && resultInfo) {
    return (
      <>
        <Helmet>
          <title>Результат</title>
          <meta name='description' content='Зрозумій себе — Сільпо розкриває все про ваше Я.' />
        </Helmet>
        <div className='header-padding'>
          <div style={{ fontSize: 40, textAlign: 'center' }}>
            <div className='user-cabinet__wrapper'>
              <div className='user-cabinet__top'>
                <CharTitle name={resultInfo.name} isyou={true} />
                {!isSignedIn && (
                  <div>
                    <a href='/oauth2/login/'>
                      <PrimaryButton classname='user-cabinet__save-result' text='Зберегти результат' />
                    </a>
                  </div>
                )}
                <CharImg name={resultInfo.name} img={resultInfo.img} />
                <div className='user-cabinet__input-wrapper share-desktop-btn'>
                  Поділіться інформацією про себе з друзями
                  <ShareButtons name={resultInfo.name} result={resultId} />
                </div>
              </div>
              <div className='user-cabinet__desc'>
                <CharacterDesc desc={resultInfo.text} personText={resultInfo.persontext} />
              </div>
              <div className='share-mobile-btn'>
                Поділіться інформацією про себе з друзями
                <ShareButtons name={resultInfo.name} result={resultId} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!resultId) {
    return <Redirect to='/test' />;
  } else {
    return <div className='user-cabinet__loading'>Loading result...</div>;
  }
};

export default Result;
