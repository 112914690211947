import React from "react";
import { connect } from "react-redux";
import { openLogin } from "../../redux/actions/uiActions";

import "./UserHeaderIcon.scss";

const UserHeaderIcon = ({ openLogin }) => {
  return (
    <a
      href="/oauth2/login/"
      className="user-header-icon"
      aria-label="Відкрити вікно входу в систему"
    ></a>
  );
};

export default connect(null, { openLogin })(UserHeaderIcon);
