import React from 'react';
import {useBoop} from '../../hooks/use-boop';
import {animated} from 'react-spring';

const QuestionItem = ({onChange, value, checked, text, order}) => {
  let direction = order === 1 ? 1 : -1;

  const [style, trigger] = useBoop({x: 1, rotation: 5 * direction,y: 10, scale: 0.9});
  return (
    <animated.label onClick={trigger} style={style} className="questions__item">
      <input
        onChange={onChange}
        type="radio"
        value={value}
        name="answer"
        className="visually-hidden questions__item-input"
        checked={checked}
      />
      <span className="questions__radiobox">{text}</span>
      <span className="questions__bg-cloud" />
    </animated.label>
  );
};

export default QuestionItem;
