import React from 'react';
import {ReactComponent as FacebookIcon} from './../../images/icons/facebook-icon.svg'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {useActions} from '../../hooks/useActions';

import facebookAuth from './FacebookAuth.module.scss';

const FacebookAuth = () => {

  const {signupFacebook} = useActions()

  return (
    <FacebookLogin
      appId="184729643101379"
      callback={signupFacebook}
      render={renderProps => (
        <button onClick={renderProps.onClick} className={facebookAuth.button}>
          <FacebookIcon width="29" height="30" className={facebookAuth.icon} />
          <span>Увійти через Facebook</span>
        </button>
      )}
    />
  );
};

export default FacebookAuth;
