import React from 'react';

import './PrimaryButton.scss';

const PrimaryButton = ({text, type, classname}) => {
  return (
    <button type={type} className={`primaryButton ${classname}`}>
      {text}
    </button>
  );
};

PrimaryButton.defaultProps = {
  type: 'button'
}

export default PrimaryButton;
