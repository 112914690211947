import React from 'react';
import './HeroInfo.scss'
import GoToTest from '../GoToTest/GoToTest';

const HeroInfo = () => {
  return (
    <GoToTest classname={'hero-info__button'}/>
  );
};

export default HeroInfo;
