import React, { useState, useRef } from "react";

import "./EditInput.scss";

const EditInput = ({ value }) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();

  return (
    <section className="edit-input">
      <input
        value={inputValue}
        ref={inputRef}
        onChange={(e) => setInputValue(e.target.value)}
        type="text"
        className="edit-input__control"
        disabled
      />
    </section>
  );
};

export default EditInput;
