import React from 'react';
import {NavLink} from 'react-router-dom';

import navigation from './SiteNavigation.module.scss'

const SiteNavigation = () => {
  return (
    <nav className={navigation.row}>
      <ul className={navigation.list}>
        <li className={navigation.item}>
          <NavLink className={`${navigation.link} ${navigation.itemPink}`} to="/test">Пройти тест</NavLink>
        </li>
        <li className={navigation.item}>
          <NavLink className={`${navigation.link} ${navigation.itemGreen}`} to="/persons">Типи особистості</NavLink>
        </li>
        <li className={navigation.item}>
          <NavLink className={`${navigation.link} ${navigation.itemOrange}`} to="/methodology">Методологія</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SiteNavigation;
