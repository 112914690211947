import React from 'react';
import GoogleAuth from '../GoogleAuth/GoogleAuth';
import FacebookAuth from '../FacebookAuth/FacebookAuth';
import AppleAuth from '../AppleAuth/AppleAuth';

//styles
import './ApiLoginWidget.scss'

const ApiLoginWidget = () => {
  return (
    <div className="apiWidget">
      <GoogleAuth />
      <FacebookAuth />
      <AppleAuth />
    </div>
  );
};

export default ApiLoginWidget;
