import React from 'react';
import login from '../components/Login/Login.module.scss';
import history from '../history';
import './Methodology.scss';
import Helmet from 'react-helmet';
import GoToTest from '../components/GoToTest/GoToTest';
import CloseBtn from '../components/CloseBtn/CloseBtn';

import imgSilpo from './img/silpo-logo.png';
import imgPartner from './img/meta-logo.png';

const Methodology = () => {
  return (
    <>
      <Helmet>
        <title>Методологія</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
      <div className="methodology">
        <div className={login.popup}>
          <div onClick={() => history.push('/')} className={login.overlay} />
          <div className={`${login.body} methodology__body`}>
            <CloseBtn
              onClickHandler={() => history.push('/')}
              className={`${login.closeBtn} methodology__close`}
              ariaLabel="Перейти на главную"
            />
            <div className="methodology__body-wrapper">
              <div className="methodology__body-top">
                <img src={imgSilpo} alt="Логотип Сільпо" />
                <img src={imgPartner} alt="Логотип партнера" />
              </div>
              <div className="methodology__body-content">
                <h1 className="methodology__body-title">Відкрийте потаємні двері до розуміння свого власного «Я»!</h1>
                <p>Чому цей тест – не «ще один тест з Інтернету»?</p>
                <p>Тому що це авторська унікальна розробка від команди експертів соціології, психології та спеціалістів
                  з
                  нейролінгвістичного програмування. Дана типологія ґрунтується на звичках сприйняття інформації,
                  прийняття рішень, мислення та поведінки, що характерні для багатьох із нас.</p>
                <p>Відповідаючи на прості запитання, ви отримаєте нагоду визначити свій унікальний тип особистості,
                  краще
                  зрозумієте, на чому звикли фокусуватися, на що зважаєте, коли робите вибір, що вас мотивує, як
                  ставитеся
                  до інших. Ще краще розуміння себе допоможе під час звичайного шопінгу чи в роботі над складним
                  проєктом,
                  у плануванні відпустки чи майбутньому навчанні та й просто розважить.</p>
                <p>Пройдіть тест, дізнайтеся цікаве та нове про своє «Я».</p>
              </div>
            </div>
            <GoToTest classname="methodology__test" />
            <div className={`${login.cloud} login-cloud methodology__cloud`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Methodology;
