import React from "react";
import closeBtn from "./CloseBtb.module.scss";

const CloseBtn = ({ ariaLabel, className, onClickHandler }) => {
  return (
    <button
      onClick={onClickHandler}
      className={`${closeBtn.button} ${className}`}
      aria-label={ariaLabel}
    />
  );
};

CloseBtn.defaultProps = {
  ariaLabel: "Закрити спливаюче вікно",
};

export default CloseBtn;
