import React from 'react';
import {Link} from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';

import errImg from './img/404/404.svg';

import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="header-padding not-found">
      <div className="not-found__row">
        <h1>
          <img src={errImg} alt="" />
        </h1>
        <p>сторінку не знайдено</p>
        <Link to="/" className="">
          <PrimaryButton text="На головну" />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
