import {
  SIGN_IN,
  SIGN_OUT,
  SET_LOGIN_ERROR,
  SAVE_RESULT,
  RESET_ERRORS,
} from "../types";

const initialState = {
  // authenticated: true,
  authenticated: null,
  name: "",
  result: null,
  email: "",
  phone: "",
  error: {},
};

const userReducer = (state = initialState, action) => {
  // console.log("action", action);
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
        authenticated: action.payload.authenticated,
        error: {},
      };
    case SAVE_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    case SIGN_OUT:
      return { ...state, authenticated: false, error: {} };
    // return {...state, authenticated: true, error: {}}
    case SET_LOGIN_ERROR:
      return { ...state, error: action.payload };
    case RESET_ERRORS:
      return { ...state, error: {} };
    default:
      return state;
  }
};

export default userReducer;
