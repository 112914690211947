import React from 'react';

import './CharImg.scss'

const CharImg = ({img, name}) => {
  return (
    <div className="charImg">
      <img src={`/${img}`} alt={name} />
    </div>
  );
};

export default CharImg;
