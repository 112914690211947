import {
  CLOSE_LOGIN,
  OPEN_LOGIN,
  TOGGLE_NAVIGATION,
  CLOSE_NAVIGATION,
  LOADING_UI,
  LOADING_DONE
} from '../types';

const initialState = {
  showLogin: false,
  showNavigation: false,
  loading: false
}

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return {...state, showLogin: true}
    case CLOSE_LOGIN:
      return {...state, showLogin: false}
    case TOGGLE_NAVIGATION:
      //TODO Не знав як краще зробити, один екшен який буде перемикати відкриття закриття навігації чи два окремих
      return {...state, showNavigation: !state.showNavigation}
    case CLOSE_NAVIGATION:
      return {...state, showNavigation: false}
    case LOADING_UI:
      return {...state, loading: true}
    case LOADING_DONE:
      return {...state, loading: false}
    default:
      return state
  }
}

export default uiReducer;
