import React from 'react';
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux';

const GoToTest = ({classname}) => {
  const result = useSelector(({user}) => user.result);
  const text = result ? 'Пройти знову' : 'Зрозуміти себе'
  return (
    <Link to="/test" className={classname}>
      <span className={`primaryButton`}>{text}</span>
    </Link>
  );
};

export default GoToTest;
