import React, {useState, useEffect} from 'react';
import './CompleteTestCount.scss';
import restApi from '../../api/restApi';

const CompleteTestCount = () => {

  const [count, setCount] = useState('');

  useEffect(() => {
    restApi.getTestsCount()
      .then(data => setCount(data))
  }, []);


  return (
    <p className="complete-test">{count}</p>
  );
};

export default CompleteTestCount;
