import React from 'react';
import risk from './img/riskman.png'

import './RiskMain.scss';

const RiskMan = () => {
  return (
    <div className="risk-man">
      <img src={risk} alt=""  className="risk-man__img"/>
    </div>
  );
};

export default RiskMan;
