import produce from 'immer';
import {
  ADD_QUESTION_RESULT,
  REMEMBER_STEP,
  RESET_QUESTIONS
} from '../types';

/*const saveAnswerStateToStorage = (state) => {
  localStorage.removeItem('answers');
  localStorage.setItem('answers', JSON.stringify(state))
}

const deleteAnswersFromStorage = () => {
  localStorage.removeItem('answers');
}*/

let initialState;
if (localStorage.getItem('answers')) {
  initialState = JSON.parse(localStorage.getItem('answers'));
} else {
  initialState = {
    // result: ['a', 'a', 'b', 'b', 'a', 'a', 'a', 'b' ,'b', 'a', 'a', 'b', 'b', 'a', 'a', 'a', 'b', 'a'],
    result: [],
    step: 0
  }
}

const questionsReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case ADD_QUESTION_RESULT:
      const {step, value} = action.payload;
      state.result[step] = value;
      return state;
    case REMEMBER_STEP:
      state.step = action.payload;
      return state
    case RESET_QUESTIONS:
      state.step = 0;
      state.result = [];
      return state
    default:
      return state
  }
}, initialState)


export default questionsReducer
