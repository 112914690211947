import React, {Fragment} from 'react';
import {animated, useTrail, config} from 'react-spring';

import './CloudQuestion.scss';

import small from './img/small.png';
import middle from './img/middle.png';
import question1 from './img/large.png';
import question2 from './img/cloud1.png';
import question3 from './img/cloud2.png';

const CloudQuestion = ({question, invert}) => {

  const imgConfig = {
    question1: [small, middle, question1],
    question2: [small, middle, question2],
    question3: [small, middle, question3]
  }

  const isInvert = invert ? 'cloud-question__img-invert' : 'cloud-question__img';

  const currentQuestion = imgConfig[question]

  const trail = useTrail(currentQuestion.length, {
    config: {
      clamp: false,
      ...config.gentle,
      easing: 'ease'
    },
    from: {marginLeft: -20, opacity: 1, transform: 'scale(0)'},
    to: {marginLeft: 20, opacity: 1, transform: 'scale(1)'},
    delay: 750,
  })

  return (
    <Fragment>
      {trail.map((props, index) => {
        return (
          <animated.img
            key={currentQuestion[index]}
            style={props}
            src={currentQuestion[index]}
            className={`${isInvert} ${isInvert}--${index + 1}`}
          >
            {currentQuestion[index].username}
          </animated.img>
        )
      })}
    </Fragment>
  );
};

export default CloudQuestion;
