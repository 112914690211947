import React from 'react';
import './Methodology.scss';
import Helmet from 'react-helmet';

const License = () => {
  return (
    <>
      <Helmet>
        <title>Публічна оферта</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
      <div className="license header-padding">

        <div className="license__body">
          <div className="methodology__body-content" style={{maxWidth: '700px', margin: '0 auto'}}>
            <h1 className="methodology__body-title">Політика інформаційної безпеки </h1>
            <p>9.1. Заповнення Покупцем контактної інформації в особистому кабінеті та шляхом натискання кнопки «Підтвердити» про надання дозволу на обробку своїх контактних даних засвідчує добровільну згоду (дозвіл) Покупця на обробку Продавцем своїх контактних даних відповідно до сформульованої мети їхньої обробки, в тому числі право Продавця передавати такі дані в разі зміни Продавця, а також підтверджує, що особу повідомлено про її права та мету збору її контактних даних. </p>
            <p>9.2. Продавець залишає за собою право на використання контактних даних Покупця для інформування останнього про статус оформленого ним замовлення. Продавець може надсилати СМС-повідомлення та документи розрахунку на email-адресу.</p>
            <p>10. Продавець на власний розсуд має право вносити будь-які зміни в чинні Правила продажу товарів з асортименту магазинів «Сільпо» через Інтернет у будь-який час. Зміни не будуть стосуватися замовлень, які вже створено. Зміни набирають чинності з моменту їхньої публікації на вебсайті shop.silpo.ua чи в мобільному додатку «Сільпо».</p>
            <p>11. Продавець залишає за собою право призупинити або припинити дію цих Правил у будь-який час, повідомивши про це безпосередньо на вебсайті silpo.ua. Публікація цих Правил здійснюється шляхом розміщення їх на вебсайті shop.silpo.ua чи в мобільному додатку «Сільпо».</p>
          </div>
        </div>
      </div>


    </>
  );
};

export default License;
