import React from 'react';
import './cloud-woman.scss';
import {animated} from 'react-spring';
//img
import woman from './img/cloud-left.png';

import CloudQuestion from '../CloudQuestion/CloudQuestion';

const CloudWoman = () => {

  return (
    <div className="cloud-woman">
      <div className="cloud-woman__clouds">
        <div className="cloud-woman__text-wrapper">
          <span className="cloud-woman__text cloud-question__text-anim">Відповідайте чесно</span>
          <CloudQuestion question={'question1'} />
        </div>

      </div>
      <animated.img src={woman} alt="woman" />
    </div>
  );
};


export default CloudWoman;
