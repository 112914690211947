import React from 'react';

import SkippingMan from '../SkippingMan/SkippingMan';
import Labels from '../Labels/Labels';

import packageImg from './img/package.png';
import packageImgBack from './img/package_back.png';
import packageEar from './img/package_ear.png';
import img1 from './img/package_1.png';
import img2 from './img/package_2.png';
import img3 from './img/package_3.png';
import img4 from './img/package_4.png';
import img5 from './img/package_5.png';
import img6 from './img/package_6.png';
import img7 from './img/package_7.png';
import img8 from './img/package_8.png';
import img9 from './img/package_9.png';
import img10 from './img/package_10.png';
import img11 from './img/package_11.png';
import pinkFruit from '../../../images/chars/pink-fruit.png';
import img13 from './img/package_13.png';
import img14 from './img/package_14.png';
import img15 from './img/package_15.png';
import img16 from './img/package_16.png';
import img17 from './img/package_17.png';

import bigCloud2 from '../../../images/clouds/big-cloud_2.png';

import './package.scss';

const Package = () => {
  return (
    <div className="package">

      <SkippingMan />

      <img src={bigCloud2} alt="cloud" className="package__cloud" />
      <img src={img14} alt="grass" className="package__grass" />
      <img src={img17} alt="grass" className="package__grass-2" />
      <img src={img15} alt="grass" className="package__grass-1" />


      <img src={img1} alt="man" className="package__man"/>

      <img src={img2} alt="fairy" className="package__fairy" />
      <img src={img16} alt="fairy" className="package__pepper" />

      <img src={img9} alt="bacon" className="package__bacon" />
      <img src={img4} alt="catman" className="package__catman" />
      <img src={img7} alt="watermelon" className="package__watermelon" />
      <img src={img3} alt="cow" className="package__cow" />


      <img src={packageImg} alt="package" className="package__item" />
      <img src={packageImgBack} alt="package" className="package__back" />
      <img src={packageEar} alt="package" className="package__ear" />
      <img src={img8} alt="noodles" className="package__noodles" />
      <img src={img13} alt="ladder" className="package__ladder" />
      <img src={img10} alt="pea" className="package__pea" />
      <img src={img11} alt="cabbage" className="package__cabbage" />
      <img src={pinkFruit} alt="pink-fruit" className="package__pink-fruit" />
      <img src={img5} alt="water" className="package__water" />
      <img src={img6} alt="paint" className="package__paint" />
      <Labels />

    </div>
  );
};

export default Package;
