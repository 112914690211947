import React from 'react';
import PropTypes from 'prop-types';

import customInput from './CustomInput.module.scss'

  const renderError = (errorsCheck, backendError) => {
    const {touched, error} = errorsCheck;
    if (touched && error) {
      return (
        <small className={customInput.error}>{error}</small>
      )
    } else if (backendError) {
      return <small className={customInput.error}>{backendError}</small>
    }
  }

const CustomInput = (props) => {

  const {
    label,
    type,
    inputMode,
    placeholder,
    autoComplete,
    formProps,
    errorsCheck,
    backendError
  } = props;

  const isError = (errorsCheck.error && errorsCheck.touched) || backendError;

  return (
    <label className={customInput.inputWrapper}>
      {/*<span className={customInput.label}>{label}</span>*/}
      <input
        className={`${customInput.input} ${isError ? customInput.inputError: ''}`}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        autoComplete={autoComplete}
        {...formProps} />
      {/*{renderError(errorsCheck, backendError)}*/}
    </label>

  );
};

CustomInput.defaultProps = {
  type: 'text',
}

CustomInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  formProps: PropTypes.object
}

export default CustomInput;
