import React from "react";
//redux-form
import { Field, reduxForm } from "redux-form";
// components
import CustomInput from "../CustomInput/CustomInput";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
//styles
import loginForm from "./LoginForm.module.scss";
//config
import { registrationInputConfig } from "./config";
import { useSelector } from "react-redux";
const { name, email, password } = registrationInputConfig;

const formMode = {
  registration: [name, email, password],
  authorization: [email, password],
  reset: [email],
  newPassword: [password],
};

// use outside component special for redux form
const renderField = (formProps) => {
  return (
    <CustomInput
      backendError={formProps.backendError}
      {...formProps.config}
      errorsCheck={formProps.meta}
      formProps={formProps.input}
    />
  );
};

const RegistrationForm = ({
  mode,
  submitText,
  onSubmit,
  handleSubmit,
  render,
}) => {
  const inputError = useSelector(({ user }) => user.error);
  const renderModeInputs = () => {
    return formMode[mode].map((config) => {
      // save name for redux form in config file
      const { name, validation, ...rest } = config;
      return (
        <Field
          key={name}
          name={name}
          validate={[...validation]}
          component={renderField}
          config={rest}
          backendError={inputError[rest.type]}
        />
      );
    });
  };

  return (
    // handleSubmit props from ReduxForm HOC
    <form
      className={loginForm.form}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className={loginForm.inputWrapper}>
        {renderModeInputs()}
        {render()}
      </div>
      <PrimaryButton
        classname="primaryButton--form"
        type="submit"
        text={submitText}
      />
    </form>
  );
};

RegistrationForm.defaultProps = {
  render: () => {},
};

export default reduxForm({
  form: "registrationForm",
})(RegistrationForm);
