import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';
import uiReducer from './reducers/uiReducer';
import userReducer from './reducers/userReducer';
import questionsReducer from './reducers/questionsReducer';
import psychotypeReducer from './reducers/psychotypeReducer';

const initialState = {};
const middleware = [thunk];

const reducers = combineReducers({
  ui: uiReducer,
  form: formReducer,
  user: userReducer,
  questions: questionsReducer,
  psychotypes: psychotypeReducer
})

export const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
  )
);
