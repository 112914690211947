import React from "react";
import ApiLoginWidget from "../ApiLoginWidget/ApiLoginWidget";
import LoginForm from "../LoginForm/LoginForm";
import { useActions } from "../../hooks/useActions";

//styles
import registration from "./Registration.module.scss";

const Registration = ({ setTab }) => {
  const { signupUser, resetFormErrors } = useActions();
  const onSubmit = (formValues) => {
    signupUser(formValues);
  };

  return (
    <div className={registration.row}>
      <div className={registration.titleWrapper}>
        <h2
          className={`decorative-title decorative-title--registration ${registration.title}`}
        >
          Приєднатися
        </h2>
      </div>
      <LoginForm
        mode="registration"
        submitText="Приєднатися"
        onSubmit={onSubmit}
      />
      <div className={registration.bottomWrapper}>
        <span className={registration.forgetText}>
          {" "}
          Вже зареєстровані?
          <button
            onClick={() => {
              resetFormErrors();
              setTab("signIn");
            }}
            className={registration.forgetPasswordBtn}
            type="button"
          >
            &nbsp;Заходьте
          </button>
        </span>
        <span>або</span>
      </div>
      <ApiLoginWidget />
    </div>
  );
};

export default Registration;
