import React from 'react';
import shipsImg from './img/ships.png';
import bunBird from './img/bun-bird.png';
import aquariumImg from './img/aquarium.png';
import risikImg from './img/risik.png';
import birdsTop from './img/birds-top.svg';
import birdsBottom from './img/birds-bottom.svg';

import './QuestionImg.scss';

const QuestionImg = () => {
  return (
    <>
      <div className="question-img1">
        <img src={shipsImg} alt="" />
      </div>
      <div className="question-img2">
        <img src={bunBird} alt="" />
      </div>
      <div className="question-img3">
        <img src={aquariumImg} alt="" />
      </div>
      <div className="question-img4">
        <img src={risikImg} alt="" />
      </div>
      <div className="question-img5">
        <img src={birdsTop} alt="" />
      </div>
      <div className="question-img6">
        <img src={birdsBottom} alt="" />
      </div>
    </>
  );
};

export default QuestionImg;
