import React from 'react';
import man from './img/man.png';
import './SkippingMan.scss'

const SkippingMan = () => {
  return (
    <div className="skipping-man">
      <img src={man} alt="" className="skipping-man__img"/>
    </div>
  );
};

export default SkippingMan;
