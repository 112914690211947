import React from 'react';
import plane from './img/plane.png'
import './Plane.scss';

const Plane = () => {
  return (
    <div className="plane">
      <img src={plane} alt="" className="plane__img"/>
    </div>
  );
};

export default Plane;
