import React from 'react';
import lighter from './img/lighter.png'
import './LighterMan.scss';

const LighterMan = () => {
  return (
    <div className="lighter-man">
      <img src={lighter} alt="" />
    </div>
  );
};

export default LighterMan;
