import {useState, useCallback, useEffect} from 'react';
import {useSpring} from 'react-spring';
import {usePrefersReducedMotion} from './usePrefersReducedMotion';

export const useBoop = ({
                   x = 0,
                   y = 0,
                   rotation = 0,
                   scale = 1,
                   timing = 150,
                   springConfig = {
                     tension: 300,
                     friction: 30
                   }
                 }) => {

  const prefersReducedMotion = usePrefersReducedMotion();
  const [isBooped, setIsBooped] = useState(false);

  const style = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: isBooped
      ? `translate(${x}px, ${y}px) rotate(${rotation}deg) scale(${scale})`
      : `translate(0px, 0px) rotate(0deg) scale(1)`,
    config: springConfig
  })

  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false)
    }, timing)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isBooped, timing]);

  const trigger = useCallback(() => {
    setIsBooped(true)
  }, []);

  let applicableStyle = prefersReducedMotion ? {} : style;

  return [applicableStyle, trigger]
}
