import React, {useState, useEffect} from 'react';
import restApi from '../../api/restApi';
// redux
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
// components
import ToggleNavigation from '../ToggleNavigation/ToggleNavigation';
import SiteNavigation from '../SiteNavigation/SiteNavigation';
import UserHeaderIcon from '../UserHeaderIcon/UserHeaderIcon';
import CompleteTestCount from '../CompleteTestCount/CompleteTestCount';
// styles
import './SiteHeader.scss'
import dreamer from './img/dreamer.png'
import emptyUser from '../UserHeaderIcon/images/user-icon.svg'
import logo from './img/logo.png'

const SiteHeader = () => {
  const isSignedIn = useSelector(({user}) => user.authenticated);
  const showNavigation = useSelector(({ui}) => ui.showNavigation);
  const result = useSelector(({user}) => user.result);
  const [userPsychotype, setUserPsychotype] = useState('');

  useEffect(() => {
    if (isSignedIn && result) {
      restApi.getPsychotypeInfo(result)
        .then(data => setUserPsychotype(data))
    }
  }, [isSignedIn, result]);

  const img = userPsychotype?.smallimg ? `/${userPsychotype.smallimg}` : emptyUser;

  const renderButton = () => {
    if (isSignedIn === null) {
      return <span>Loading</span>
    } else if (isSignedIn) {
      return (
        <div>
          <Link to="/cabinet">
            <img src={img} alt="" />
          </Link>
        </div>
      )
    }
    return <UserHeaderIcon />
  }

  const navigationClasses = showNavigation ? 'site-header__navigation site-header__navigation--open' : 'site-header__navigation';

  return (
    <header className="site-header">
      <div className="wrapper">
        <div className="site-header__row">
          <div className="site-header__toggle-wrapper">
            <ToggleNavigation />
          </div>
          <div className={navigationClasses}>
            <div className="site-header__nav-wrapper">
              <Link to="/" className="site-header__logo">
                <img src={logo} alt="logo" />
              </Link>
              <SiteNavigation />
              <img src={dreamer} alt="Замрійник" className="site-header__nav-img" aria-hidden="true" />
              {/*<CompleteTestCount />*/}
              {/*<p className="site-header__guest-desc">Гостей краще пізнали себе</p>*/}
              <div className="site-header__license">
                <Link to="/license">Публічна оферта</Link>
                <Link to="/confidentiality">Політика конфіденційності</Link>
              </div>
            </div>
            <div className="site-header__decorative-bg" />
          </div>
          {/*Поставить класс*/}
          {renderButton()}
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
