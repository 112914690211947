import React, { useEffect } from "react";
import history from "../../history";
import { useActions } from "../../hooks/useActions";

const MainLayout = ({ children }) => {
  const { closeNavigation, getUserInfo } = useActions();
  useEffect(() => {
    history.listen(() => {
      closeNavigation();
    });
  }, [closeNavigation]);

  // TODO Винести в окремий кастомний хук
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return <>{children}</>;
};

export default MainLayout;
