import {
  ADD_QUESTION_RESULT,
  REMEMBER_STEP,
  RESET_QUESTIONS
} from '../types';

export const addQuestion = (info) => (dispatch) => {
  dispatch({type: ADD_QUESTION_RESULT, payload: info});
};

export const rememberStepAction = (step) => (dispatch) => {
  dispatch({type: REMEMBER_STEP, payload: step})
}

export const resetQuestionAction = () => (dispatch) => {
  dispatch({type: RESET_QUESTIONS});
}
