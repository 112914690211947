import React from 'react';
import './Methodology.scss';
import Helmet from 'react-helmet';

const Confidentiality = () => {
  return (
    <>
      <Helmet>
        <title>Політика конфіденційності</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
      <div className="license header-padding">
        <div className="license__body">

          <div className="methodology__body-content" style={{maxWidth: '700px', margin: '0 auto'}}>
            <h1 className="methodology__body-title">Реєстрація та використання даних</h1>
            <p>Заповнена анкета та активований чек-бокс «Я погоджуюсь з умовами» засвідчує добровільну згоду (дозвіл) особи на обробку її персональних даних, внесених у відповідну форму, та використання для автоматичної реєстрації у програмі «Власний Рахунок». У випадку коли учасник був раніше зареєстрований у програмі «Власний Рахунок», нова анкета не створюється. Крім того, учасник дає право передавати дані для отримання рекламної інформації через різні канали комунікації, а також підтверджує, що особі повідомлено про її права та мету збору її персональних даних у рамках і з метою реалізації програми «Власний Рахунок» та передачі цих даних менеджеру програми «Власний Рахунок». Офіційні правила програми «Власний Рахунок» <a rel="noreferrer" href="https://silpo.ua/pcs/pravila/Vlasnyi_Rakhunok.pdf" target="_blank">тут.</a></p>
            <p>Учасник надає менеджеру право збирати, реєструвати, накопичувати, обробляти, зберігати, адаптувати, змінювати, знищувати, поновлювати, знеособлювати, поширювати, використовувати свої персональні дані, внесені учасником у реєстраційну анкету. Менеджер не несе відповідальності за точність та достовірність наданих Учасником даних та не повинен їх перевіряти. Учасник несе повну відповідальність за точність та/або достовірність наданих даних та за наслідки, спричинені наданням недостовірних та неточних реєстраційних даних.</p>
            <p>Результати, отримані після проходження тесту, будуть внесені до анкети у програмі «Власний Рахунок», проаналізовані та враховані при формуванні персональних пропозицій, комунікації, спілкуванні та дадуть змогу зрозуміти вподобання учасника.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confidentiality;
