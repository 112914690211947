import React, {useEffect, useState} from 'react';
import CharTitle from '../components/CharacterInfo/CharTitle/CharTitle';
import CharImg from '../components/CharacterInfo/CharImg/CharImg';
import CharacterDesc from '../components/CharacterInfo/CharacterDesc/CharacterDesc';
import restApi from '../api/restApi';
import {useSelector} from 'react-redux';
import Helmet from 'react-helmet';
import ShareButtons from '../components/ShareButtons/ShareButtons';

import GoToTest from '../components/GoToTest/GoToTest';

const PersonItem = ({match}) => {
  const id = match.params.id;
  const result = useSelector(({user}) => user.result);
  const isYou = String(result) === id;


  const [psychotype, setPsychotype] = useState('');

  useEffect(() => {
    restApi.getPsychotypeInfo(id)
      .then(data => setPsychotype(data))
  }, [id]);

  if (psychotype) {
    return (
      <>
        <Helmet>
          <title>{psychotype.name}</title>
          <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
        </Helmet>
        <section className="header-padding person-item-page">
          <div>
            <div className="user-cabinet__wrapper">
              <div className="user-cabinet__top">
                <CharTitle name={psychotype.name} isyou={isYou} />
                <CharImg name={psychotype.name} img={psychotype.img} />
                <GoToTest classname={'methodology__test'} />
                {isYou && (<div className="user-cabinet__input-wrapper share-desktop-btn">
                  Поділіться інформацією про себе з друзями
                  <ShareButtons name={psychotype.name} result={psychotype.number} />
                </div>)}
              </div>
              <div className="user-cabinet__desc">
                <CharacterDesc
                  desc={psychotype.text}
                  personText={psychotype.persontext} />

              </div>
              {isYou && (<div className="share-mobile-btn">
                Поділіться інформацією про себе з друзями
                <ShareButtons name={psychotype.name} result={psychotype.number} />
              </div>)}
            </div>
          </div>
        </section>
      </>
    );
  }

  return <div>Loading</div>


};

export default PersonItem;
