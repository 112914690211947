import React from 'react';
import {Router, Switch, Route} from 'react-router-dom';
//redux
import {Provider} from 'react-redux';
import {store} from '../../redux/store';
import history from '../../history';

//components
import ProtectRoute from '../../utils/ProtectRoute';
import SiteHeader from '../SiteHeader/SiteHeader';
import MainLayout from '../MainLayout/MainLayout';
import Login from '../Login/Login';
import Overlay from '../Overlay/Overlay';

//routes
import Home from '../../routes/Home';
import Cabinet from '../../routes/Cabinet';
import TestPage from '../../routes/TestPage';
import NotFound from '../../routes/NotFound';
import Result from '../../routes/Result';
import PersonList from '../../routes/PersonList';
import PersonItem from '../../routes/PersonItem';
import Methodology from '../../routes/Methodology';
import ResetPassword from '../../routes/ResetPassword';
import License from '../../routes/License';
import Confidentiality from '../../routes/Confidentiality';

const App = () => {

  return (
    <Provider store={store}>
      <Router history={history}>
        {/*Layout for listen history*/}
        <MainLayout>
          <SiteHeader />
          <Login />
          <main className="main-container">
            <Switch>
              <Route path="/" exact component={Home} />
              <ProtectRoute path="/cabinet" component={Cabinet} />
              <Route path="/test" component={TestPage} />
              <Route path="/result" component={Result}/>
              <Route path="/methodology" component={Methodology}/>
              <Route exact path="/persons" component={PersonList}/>
              <Route path="/persons/:id" component={PersonItem}/>
              <Route path="/requestreset/:token" component={ResetPassword}/>
              <Route path="/license" component={License}/>
              <Route path="/confidentiality" component={Confidentiality}/>
              <Route component={NotFound} />
            </Switch>
          </main>

          <Overlay />
        </MainLayout>
      </Router>
    </Provider>

  );
};

export default App;
