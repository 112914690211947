import {useRef, useState, useEffect} from 'react';
import SwiperCore, {Navigation, Pagination, A11y} from 'swiper';
import {Link} from 'react-router-dom';
import restApi from '../api/restApi';
import GoToTest from '../components/GoToTest/GoToTest';
import Helmet from 'react-helmet';
import {Swiper, SwiperSlide} from 'swiper/react';
import classNames from 'classnames';
import {useActions} from '../hooks/useActions';

import {useSelector} from 'react-redux';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import './PersonList.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

const PersonList = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const fractionEl = useRef(null);
  const [list, setList] = useState([]);
  const result = useSelector(({user}) => user.result);
  const {getUserInfo} = useActions();

  useEffect(() => {
    restApi.getAllPsychotypes().then(data => setList(data))
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slides = () => {
    return list.map(item => (
      <SwiperSlide key={item._id} className={classNames({'person-item--order': result === item.number})}>
        <Link to={`/persons/${item.number}`} className="person-item">
          <div className="person-item__you">та це ж ви!</div>
          <div className="person-item__img">
            <img src={item.img} alt="" />
          </div>
          <div className="person-item__text">
            <h2 className="person-item__name">{item.name}</h2>
            <p className="person-item__desc">{item.text}</p>
          </div>
        </Link>
      </SwiperSlide>
    ))
  }

  return (
    <>
      <Helmet>
        <title>Список психотипів</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
      <div className="header-padding person-list">
        <div className="wrapper">

          <div className="person-list__row">
            <div className="person-list__bottom-container">
              <div className="fake-button" />
              <GoToTest classname={'person-list__test-link'} />
              <div className="person-list__navigation">
                <button ref={navigationNextRef} className="person-list__next">
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.03076 1.30087L5.28345 5.96668L1.03076 10.6325" stroke="#67B0D9" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <div ref={fractionEl} className="person-list__fraction" />
                <button ref={navigationPrevRef} className="person-list__prev">
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.61182 10.9672L1.35913 6.30138L5.61182 1.63557" stroke="#67B0D9" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
            </div>

            <Swiper
              className="person-list__swiper"
              spaceBetween={12}
              slidesPerView={'auto'}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.params.pagination.el = fractionEl.current;
                /*              swiper.navigation.init();
                              swiper.navigation.update();*/
              }}
              // onSlideChange={(swiper) => console.log(swiper)}

              pagination={{
                'type': 'fraction',
              }}
            >
              {slides()}
              {!result && <div slot="container-start" className="person-list__overlay" />}

            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonList;
