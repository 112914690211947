import React from 'react';

import './TopClouds.scss'

import cloud1 from './img/cloud1.png';
import cloud2 from './img/cloud2.png';
import bird from './img/bird.png';

const TopClouds = () => {
  return (
    <div className="top-clouds">
      <img src={cloud2} alt="" />
      <img src={cloud1} alt="" />
      <img src={bird} alt="" width="17" height="5" className="top-clouds__bird" style={{left: '2%', top: '2%'}}/>
      <img src={bird} alt="" width="17" height="5" className="top-clouds__bird" style={{left: '5%', top: '20%'}}/>
      <img src={bird} alt="" width="17" height="5" className="top-clouds__bird" style={{left: '3%', top: '50%'}}/>
      <img src={bird} alt="" width="17" height="5" className="top-clouds__bird" style={{right: '40%', bottom: '-30%'}}/>
      <img src={bird} alt="" width="17" height="5" className="top-clouds__bird" style={{right: '44%', bottom: '-60%'}}/>
    </div>
  );
};

export default TopClouds;
