import React from 'react';

import GoogleLogin from 'react-google-login';
import {useActions} from '../../hooks/useActions';

import googleAuth from './GoogleAuth.module.scss';
import {ReactComponent as GoogleIcon} from './../../images/icons/google-icon.svg';

const GoogleAuth = () => {

  const {signupGoogle} = useActions();

  return (
      <GoogleLogin clientId={'1011321606062-k7aq59vr2aj43ql2nmp68fjmuf5refg9.apps.googleusercontent.com'}
                   onSuccess={signupGoogle}
                   render={renderProps => (
                     <button onClick={renderProps.onClick} className={googleAuth.button}>
                       <GoogleIcon width="29" height="30" className={googleAuth.icon} />
                       <span>Увійти через Google</span>
                     </button>
                   )}
                   onFailure={signupGoogle}
                   cookiePolicy={'single_host_origin'} />
  );
};

export default GoogleAuth;
