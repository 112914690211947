import React from "react";
import { connect } from "react-redux";
import { toggleNavigation } from "../../redux/actions/uiActions";

import "./ToggleNavigation.scss";

const ToggleNavigation = (props) => {
  const toggleButtonConfig = () => {
    const className = props.showNavigation
      ? "toggle-menu active"
      : "toggle-menu not-active";
    const ariaLabel = props.showNavigation
      ? "Закрити головне меню"
      : "Відкрити головне меню";
    return {
      className,
      "aria-label": ariaLabel,
    };
  };

  return (
    <button
      onClick={props.toggleNavigation}
      aria-expanded={props.showNavigation}
      {...toggleButtonConfig()}
    >
      <span className="toggle-menu__wrapper">
        <span className="toggle-menu__line toggle-menu__line--1" />
        <span className="toggle-menu__line toggle-menu__line--2" />
        <span className="toggle-menu__line toggle-menu__line--3" />
      </span>
    </button>
  );
};

const mapStateToProps = ({ ui }) => {
  return { showNavigation: ui.showNavigation };
};

export default connect(mapStateToProps, { toggleNavigation })(ToggleNavigation);
