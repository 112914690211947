import React, { useState, useRef } from "react";
//redux
import { connect } from "react-redux";
//actions
import { closeLogin } from "../../redux/actions/uiActions";
import { useCloseEscape } from "../../hooks/useCloseEscape";
//components
import Registration from "../Registration/Registration";
import PasswordRecovery from "../PasswordRecovery/PasswordRecovery";
import SignIn from "../SignIn/SignIn";
import CloseBtn from "../CloseBtn/CloseBtn";
//styles
import login from "./Login.module.scss";
import "./Login.scss";

const Login = ({ closeLogin, isOpen }) => {
  const loginCloud = useRef(null);
  const [currentFormTab, setFormTab] = useState("registration");

  useCloseEscape(closeLogin);

  const combineLoginClasses = () => {
    const currentClasses = [login.popup];
    if (!isOpen) {
      currentClasses.push(login.popupClose);
    }
    return currentClasses.join(" ");
  };

  const currentForm = {
    registration: () => <Registration setTab={setFormTab} />,
    password: () => <PasswordRecovery setTab={setFormTab} />,
    signIn: () => <SignIn setTab={setFormTab} />,
  };

  return (
    <div className={combineLoginClasses()}>
      <div onClick={closeLogin} className={login.overlay} />
      <div className={login.body}>
        <CloseBtn
          onClickHandler={closeLogin}
          className={login.closeBtn}
          ariaLabel="Закрити спливаюче вікно входу"
        />
        {currentForm[currentFormTab]()}
        <div ref={loginCloud} className={`${login.cloud} login-cloud`} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.ui.showLogin,
  };
};

export default connect(mapStateToProps, { closeLogin })(Login);
