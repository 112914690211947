import {useEffect} from 'react';

export const useCloseEscape = (callback) => {
  useEffect(() => {
    const onEscDownHandler = (evt) => {
      if (evt.keyCode === 27) {
        callback()
      }
    }
    window.addEventListener('keydown', onEscDownHandler);
    return () => {
      window.removeEventListener('keydown', onEscDownHandler);
    };
  }, [callback]);
}
