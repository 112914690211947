import React, {useEffect, useState} from 'react';
import CharTitle from '../CharacterInfo/CharTitle/CharTitle';
import CharImg from '../CharacterInfo/CharImg/CharImg';
import UserEditInputs from '../UserEditInputs/UserEditInputs';
import CharacterDesc from '../CharacterInfo/CharacterDesc/CharacterDesc';
import {useSelector} from 'react-redux';
import restApi from '../../api/restApi';
import {useActions} from '../../hooks/useActions';
import GoToTest from '../GoToTest/GoToTest';

import emptyUser from './img/empty-user-desc.png';

import './UserCabinet.scss';
import ShareButtons from '../ShareButtons/ShareButtons';


const UserCabinet = () => {
  const localResult = localStorage.getItem('resultId');
  const guid = localStorage.getItem('guid');
  const result = useSelector(({user}) => user.result);
  const {logoutUser, saveResult} = useActions();

  const [userPsychotype, setUserPsychotype] = useState('');

  useEffect(() => {
    const getUserData = async () => {
      if (localResult) {
        const data = await restApi.getPsychotypeInfo(localResult);
        setUserPsychotype(data);
        await restApi.sendResult(localResult);
        saveResult(localResult);
        localStorage.removeItem('resultId')
      } else if (result) {
        const data = await restApi.getPsychotypeInfo(result);
        setUserPsychotype(data)
      }
    }

    const sendGuid = async () => {
      if (guid) {
        await restApi.sendGuidLoginInfo(guid);
        localStorage.removeItem('guid')
      }
    }

    sendGuid();
    getUserData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localResult, result]);


  if (userPsychotype) {
    return (
      <div className="user-cabinet">
        <div className="user-cabinet__wrapper">
          <div className="user-cabinet__top">
            <CharTitle name={userPsychotype.name} isyou={true} />
            <CharImg name={userPsychotype.name} img={userPsychotype.img} />
            <div className="user-cabinet__share user-cabinet__input-wrapper share-desktop-btn">
              Поділіться інформацією про себе з друзями
              <ShareButtons name={userPsychotype.name} result={result} />
            </div>
            <div className="user-cabinet__input-wrapper" style={{maxWidth: '370px', width: '100%'}}>
              <UserEditInputs />
              <div className="user-cabinet__controls">
                <button className="user-cabinet__exit" style={{visibility: 'hidden'}}>Змінити пароль</button>
                <button className="user-cabinet__exit" onClick={logoutUser}>Вийти</button>
              </div>
            </div>
          </div>
          <div className="user-cabinet__desc">
            <CharacterDesc desc={userPsychotype.text}
                           personText={userPsychotype.persontext} />
          </div>
          <div className="share-mobile-btn">
            Поділіться інформацією про себе з друзями
            <ShareButtons name={userPsychotype.name} result={result} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="user-cabinet">
        <div className="user-cabinet__wrapper">
          <div className="user-cabinet__top">
            <div className="charImg charImg--no-bg">
              <img src={emptyUser} alt={'пусто'} />
            </div>
            <div className="user-cabinet__input-wrapper" style={{maxWidth: '370px', width: '100%'}}>
              <UserEditInputs />
              <div className="user-cabinet__controls">
                <button style={{visibility: 'hidden'}} className="user-cabinet__exit">Змінити пароль</button>
                <button className="user-cabinet__exit" onClick={logoutUser}>Вийти</button>
              </div>
            </div>
          </div>
          <div className="user-cabinet__desc user-cabinet__desc--no-result">
            <p className="user-cabinet__no-result">Пориньте у Свій внутрішній світ! Там точно багато цікавого</p>
            <GoToTest classname={'methodology__test'} />
          </div>
        </div>
      </div>
    );
  }

};

export default UserCabinet;

