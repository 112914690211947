import React from 'react';
import {useActions} from '../../hooks/useActions';
// components
import LoginForm from '../LoginForm/LoginForm';
import ApiLoginWidget from '../ApiLoginWidget/ApiLoginWidget';
// style
import registration from '../Registration/Registration.module.scss';
import signIn from './SignIn.module.scss';

const SignIn = ({setTab}) => {

  const {loginUser, resetFormErrors} = useActions();
  const onSubmit = (formValues) => {
    loginUser(formValues);
  }

  const recoveryPasswordBtn = () => {
    return (
      <span className={`${registration.forgetText} ${signIn.forgetPassword}`}>
          <button
            onClick={() => {
              resetFormErrors();
              setTab('password');
            }}
            className={registration.forgetPasswordBtn}
            type="button">&nbsp;Забули пароль?
          </button>
        </span>
    )
  }

  return (
    <div className={registration.row}>
      <div className={registration.titleWrapper}>
        <h2 className={`decorative-title decorative-title--signIn ${registration.title}`}>Вхід</h2>
      </div>
      <LoginForm
        mode="authorization"
        submitText="Увійти"
        onSubmit={onSubmit}
        render={recoveryPasswordBtn}/>
      <div className={registration.bottomWrapper}>
      <span className={registration.forgetText}> Немає акаунту?
          <button
            onClick={() => setTab('registration')}
            className={registration.forgetPasswordBtn}
            type="button">&nbsp;Зареєструватися
          </button>
        </span>
        <span>або</span>
      </div>
      <ApiLoginWidget />
    </div>
  );
};

export default SignIn
