import React from 'react';
import UserCabinet from '../components/UserCabinet/UserCabinet';
import Helmet from 'react-helmet';
const Cabinet = () => {
  return (
    <section className="header-padding">
      <Helmet>
        <title>Мій кабінет</title>
        <meta name="description" content="Зрозумій себе — Сільпо розкриває все про ваше Я." />
      </Helmet>
        <UserCabinet />
    </section>
  );
};

export default Cabinet;
