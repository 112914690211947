import { listToMatrix } from "../utils/listToMatrix";
import answersObj from "../components/Questions/answers.json";
import { useActions } from "./useActions";
import { useSelector } from "react-redux";

// Отримуємо результати та скидаємо попередній тест
export const useSaveResult = () => {
  // 18 варіантів відповідей ['a', 'a', 'b', 'b', 'a' ...],
  const pureResult = useSelector(({ questions }) => questions.result);
  const isSignedIn = useSelector(({ user }) => user.authenticated);
  const { saveResult, resetQuestionAction } = useActions();

  if (pureResult.length) {
    // Всі можливі варіанти відповідей
    const { answers } = answersObj;

    // Розбиття масиву відповідей на 6 матриць
    const matrix = listToMatrix(pureResult, 3);
    // Сортування кожної матриці
    // Беремо з кожної матриці (з 3-х результатів) варіант посередині
    const userResult = matrix.map((item) => item.sort()).map((item) => item[1]);
    // Створюємо з масиву рядок типу 'aabbaa'
    const userResultString = userResult.join("").toLowerCase();
    // знаходимо у списку об'єкт у якому результат користувача і результат зі списку збігаються
    const psychoTypeId = answers.find((item) => item.code === userResultString);
    // кладемо в редакції стейт і локал сторедж номер психотипу
    saveResult(psychoTypeId?.id);
    resetQuestionAction();
    localStorage.setItem("resultId", psychoTypeId?.id);
  }
  const resultId = useSelector(({ user }) => user.result);
  if (!isSignedIn) {
    return localStorage.getItem("resultId");
  }
  return resultId;
};
