import React from 'react';

import ballPink from './img/cloud1.png';
import ballBlue from './img/cloud2.png';
import ballOrange from './img/cloud3.png';
import sun from './img/sun.png';
import risk from './img/risk.png';
import phil from './img/phil.png';
import bird1 from './img/bird1.svg';
import bird2 from './img/bird2.svg';
import bird3 from './img/bird3.svg';

import './Clouds.scss';

const Clouds = () => {
  return (
    <div className="text-clouds">
      <div className="text-clouds__1">
        <div className="text-clouds__text-wrapper">
          <span className="text-clouds__text">Не потрібно міркувати</span>
          <img src={ballPink} alt="cloud" className="text-clouds__cloud" />
        </div>

        <img className="text-clouds__phil" src={phil} alt="char" />
        <img className="text-clouds__bird1" src={bird1} alt="bird" />
        <img className="text-clouds__bird2" src={bird2} alt="bird" />
        <img className="text-clouds__bird3" src={bird3} alt="bird" />
      </div>
      <div className="text-clouds__2">
        <div className="text-clouds__text-wrapper">
          <span className="text-clouds__text">Відповідайте чесно</span>
          <img src={ballOrange} alt="cloud" className="text-clouds__cloud" />
        </div>

        <img src={sun} alt="sun" className="text-clouds__sun" />
      </div>
      <div className="text-clouds__3">
        <div className="text-clouds__text-wrapper">
          <span className="text-clouds__text" style={{maxWidth: '70px'}}>Тест триває 5 хвилин</span>
        <img src={ballBlue} alt="cloud" className="text-clouds__cloud" />
        </div>
        <img className="text-clouds__risk" width={94} height={84} src={risk} alt="char" />
      </div>
    </div>
  );
};

export default Clouds;
